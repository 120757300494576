import { IconProps } from '@/utilities/types/Icon';

const Facilities = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 21 23"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.373 8.5v-3a4 4 0 1 0-8 0v3M1.965 9.852l-.6 6.4c-.17 1.82-.255 2.73.046 3.432a3 3 0 0 0 1.32 1.45c.672.366 1.586.366 3.413.366h8.458c1.828 0 2.742 0 3.413-.366a3 3 0 0 0 1.32-1.45c.302-.703.217-1.612.046-3.432l-.6-6.4c-.144-1.537-.216-2.305-.561-2.886a3 3 0 0 0-1.292-1.176c-.61-.29-1.382-.29-2.926-.29H6.744c-1.543 0-2.315 0-2.925.29a3 3 0 0 0-1.292 1.176c-.345.581-.418 1.35-.562 2.886Z"
      />
    </svg>
  );
};

export default Facilities;
